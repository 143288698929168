import Ember from 'ember';
import EmberObject from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../../mixins/navbar-title-mixin';
import TenantRouteMixin from '../../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../../mixins/breadcrumb-route';
import ConfirmationMixin from 'ember-onbeforeunload/mixins/confirmation';

export default Route.extend(
  AuthenticatedRouteMixin,
  NavBarTitleMixin,
  TenantRouteMixin,
  BreadcrumbRoute,
  ConfirmationMixin,
  {
    intl: service(),
    allUsers: service(),
    confirmationMessage() {
      return this.intl.t('alerts.confirm-discard-changes');
    },
    isPageDirty(model) {
      // WARNING: 2020-02-18 DJY - we encountered a bug where the model argument of this function returns
      // hasDirtyAttributes = true for the schedule, even though Ember data already shows this record as persisted
      // and updated.  We suspect this is due to the AddOn caching the state of the model incorrectly.
      // To workaround this problem, the 'saveClicked' property was introduced.
      if (Ember.testing || this.controller.cancelClicked || this.controller.saveClicked) {
        return false;
      }
      return model.hasDirtyRelationships(model);
    },
    beforeModel() {
      this.get('allUsers').loadAllUsers();
      return this._super(...arguments);
    },
    model(params) {
      return this.store.findRecord(
        'subscription/subscription',
        params.subscription_id, {
          include: [
            'subscription_filters',
            'subscription_filters.channel',
            'subscription_filters.channel.cv_features',
            'subscription_filters.channel.cv_features.tag_types',
            'subscription_filters.ptz_preset',
            'subscribers',
            'subscribers.notification_methods',
            'subscribers.notification_methods.notification_method_type',
            'schedules',
            'type'
          ].join(','),
          reload: true,
          adapterOptions: {queryParams: {context: 'alert'}}
        }
      );
    },

    setupController(controller, model) {
      this._super(...arguments);
      // NOTE: Sometimes you may edit an inspection on the Alerts page. If you do so, we need to load the correct
      // notification type for inspection, instead of the normal notification types for an Alert.
      if (model.get('type.id') === '2') {
        //TODO: in the future we should consider adding a parameter of subscription-type
        // to the notification_method_types endpoint, so we can fetch only the method types
        // that apply (and visible) to any particular type of subscription.
        // For now I will fake this out.
        return this.store.findAll('subscription/notification-method-type')
          .then(notificationTypes => {
            const realType = notificationTypes.findBy('name', 'Post-Event Email With Report');
            const fakeType = EmberObject.create({
              id: realType.get('id'),
              name: realType.get('name'),
              description: realType.get('description'),
              visible: true,
              created: realType.get('created'),
              modified: realType.get('modified'),
              realType
            });
            // pass down the list of available notification types that was already cached in model()
            controller.set('notificationTypes', [fakeType]);
          });
      } else {
        // normal alert notification types
        controller.set(
          'notificationTypes',
          this.store.findAll('subscription/notification-method-type')
        );
      }
    },
    activate() {
      this._super(...arguments);
      this.controllerFor('application')
        .set('hideNavBarRoute', true);
    },

    deactivate() {
      this._super(...arguments);
      this.controller.set('showSitePicker', false);
      this.controller.set('errors', []);
      this.controller.set('isSaving', false);
      this.controllerFor('application')
        .set('hideNavBarRoute', false);
    },

    resetController(controller, isExiting) {
      this.controller.cancelClicked = false;
      this.controller.saveClicked = false;
      if (!isExiting) return;
      // WARNING: rollbackAttributes() will remove the model from the store,
      // which will mutate relationships as we loop over them.
      // To avoid looping on a mutating list, make a copy of the array first,
      // and then loop over the copy to delete the records.
      const model = controller.get('model');
      let toDelete = model.subscriptionFilters.toArray();
      toDelete.forEach(m => m.rollbackAttributes());

      toDelete = model.subscribers.toArray();
      toDelete.forEach(sub => {
        const notificationMethods = sub.notificationMethods.toArray();
        notificationMethods.forEach(nm => nm.rollbackAttributes())
      });
      toDelete.forEach(m => m.rollbackAttributes());

      toDelete = model.schedules.toArray();
      toDelete.forEach(m => m.rollbackAttributes());

      model.rollbackAttributes();
    }
  }
);
