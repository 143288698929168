import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import Ember from 'ember';
import { observer, computed } from '@ember/object';
import { debounce } from '@ember/runloop';

const TIMESTAMP = 'date_time';
const AUTHOR = 'creator__first_name';
const REPORT_NAME = 'name';
const ASC = 'asc';
const DESC = 'desc';
export default Controller.extend({
  currentUser: service(),
  report: service(),
  intl: service(),

  queryParams: ['page',
    'page_size',
    'direction',
    'ghost',
    'order',
    'name',
    'site',
    'context_note',
    'creator',
    'date_time'],

  page: 1,
  page_size: 15,
  direction: DESC,
  ghost: false,
  order: TIMESTAMP,
  name: null,
  site: null,
  context_note: null,
  creator: null,
  date_time: null,
  showFilters: false,

  areReportsSelected: computed('model.@each.selected', function() {
    let isSelected = false;
    this.model.forEach(report => {
      if (report.get('selected')) {
        isSelected = true;
      }
      return true;
    });
    return isSelected;
  }),

  toggleDirection() {
    this.set('direction', (this.direction === ASC) ? DESC : ASC);
  },

  openFilters() {
    this.toggleProperty('showFilters');
  },

  updateReportModel: observer('page',
    'page_size',
    'direction',
    'ghost',
    'order',
    'name',
    'site',
    'context_note',
    'creator',
    'date_time', function() {
      debounce(this, 'reloadModel', 500);
    }),

  reloadModel() {
    this.store.query('report', {
      page: this.page,
      page_size: this.page_size,
      order: this.order,
      direction: this.direction,
      ghost: this.ghost,
      name: this.name,
      site: this.site,
      context_note: this.context_note,
      creator: this.creator,
      date_time: this.date_time,
    })
      .then(result => {
        this.set('model', result);
      });
  },

  actions: {
    openFilters() {
      this.toggleProperty('showFilters');
    },

    deleteReport(report) {
      if (Ember.testing ||
        window.confirm(this.intl.t('report.delete-confirmation', {'report-name': this.get('report.name')}))) {
        report.destroyRecord();
      }
    },
    sortByTitle() {
      if (this.order === REPORT_NAME) {
        this.toggleDirection();
      } else {
        this.set('order', REPORT_NAME);
      }
    },
    sortByAuthor() {
      if (this.order === AUTHOR) {
        this.toggleDirection();
      } else {
        this.set('order', AUTHOR);
      }
    },
    sortByTimestamp() {
      if (this.order === TIMESTAMP) {
        this.toggleDirection();
      } else {
        this.set('order', TIMESTAMP);
      }
    },
    checkAll() {
      this.model.forEach(report => {
        if (this.get('currentUser.vhiUser.id') == report.get('creator.id')) {
          report.set('selected', true);
        }
      });
    },
    unCheckAll() {
      this.model.forEach(report => {
        if (this.get('currentUser.vhiUser.id') == report.get('creator.id')) {
          report.set('selected', false);
        }
      });
    },
    deleteSelected() {
      if (Ember.testing ||
        window.confirm(this.intl.t('report.delete-selected'))) {
        this.model.forEach(report => {
          if (report.get('selected')) {
            report.destroyRecord();
          }
        })
      }
    },
    hideFilters() {
      this.set('showFilters', false);
    },
  }
});
