import { Factory, faker } from 'ember-cli-mirage';

export default Factory.extend({
  firstName() {
    return faker.name.firstName();
  },
  lastName() {
    return faker.name.lastName();
  },
  dateJoined() {
    faker.date.past();
  },
  email() {
    return faker.internet.email();
  },
  intercon: false,
  is_staff: false,
  is_superuser: false,
  last_login: null,
  permissions() {
    return [];
  },
  username() {
    return faker.internet.userName();
  },

});
