import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../mixins/navbar-title-mixin';
import TenantRouteMixin from '../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../mixins/breadcrumb-route';
import { t } from 'ember-intl';
import { MAP_VIEWED } from '../services/analytics';
import { inject as service } from '@ember/service';

export default Route.extend(AuthenticatedRouteMixin,
  NavBarTitleMixin,
  TenantRouteMixin,
  BreadcrumbRoute, {
    titleToken: t('navbar.map'),
    queryParams: {
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      },
      lat: {},
      lng: {},
    },
    analytics: service(),

    model(params) {
      return RSVP.hash({
        sites: this.store.query('site', {
          page: params.page,
          page_size: params.page_size,
          include: 'latest_image'
        }),
        publicCameras: this.store.findAll('publiccamera')
      });
    },

    setupController() {
      this.set('breadcrumbs', [
        {
          name: this.intl.t('navbar.map')
        }
      ]);
      this._super(...arguments);
    },

    activate() {
      this._super(...arguments);
      this.analytics.trackMultiple(MAP_VIEWED, {
      });
      this.controllerFor('application').set('mapRoute', true);
    },

    deactivate() {
      this._super(...arguments);
      this.controllerFor('application').set('mapRoute', false);
    },
  });
