import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Ember from 'ember';
import { later, cancel } from '@ember/runloop';
import BreadcrumbRoute from '../../mixins/breadcrumb-route';

export default Route.extend(BreadcrumbRoute, {
  titleToken: function() {
    return this.modelFor('site').name;
  },
  intl: service(),
  model: function(params) {
    let site = this.modelFor('site');
    let page = params.page ? params.page : 1;
    let pageSize = params.page_size ? params.page_size : 500;
    return this.store.query('datalogger', {
      page: page,
      site: site.get('id'),
      page_size: pageSize,
    });
  },
  setupController(controller) {
    this.set('breadcrumbs', [{
      name: this.intl.t('navbar.sites'),
      link: 'sites'
    },
    {
      name: this.controllerFor('site').get('model.name')
    }]);
    this._super(...arguments);
    controller.set('site', this.controllerFor('site').get('model'));
    this.scheduleDataUpdate();
  },
  /**
   * every minute check for updates to the cameras
   **/
  scheduleDataUpdate() {
    //you can't do continuous runloops with ember testing!
    if (Ember.testing) {
      return;
    }
    let delayedFunc = later(this, function() {
      this.backgroundUpdateSite().then(() => {
        this.scheduleDataUpdate();
      })
    }, 60000);
    this.set('delayedFunc', delayedFunc);
  },
  backgroundUpdateSite() {
    return this.store.query('datalogger', {
      page: 1,
      site: this.controllerFor('site').get('model.id'),
      page_size: 500,
      reload: true
    })
      .then((loggers) => {
        this.controllerFor('site.dataloggers').set('model', loggers);
      });
  },
  actions: {
    willTransition() {
      if (this.delayedFunc) {
        cancel(this.delayedFunc);
      }
      return this._super(...arguments);
    },
  }
});
