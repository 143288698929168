import ApplicationAdapter from './application';
import ENV from '../config/environment';
import fetch from 'fetch';
import { reject } from 'rsvp';


export default ApplicationAdapter.extend({
  //overriding host on tenant as this is the main entry point to
  //setting up our api urls, everything else should be the same.
  host: ENV.vhiProtocol + '://' + window.location.host.split('.')[0] + '.' + ENV.vhiServer,
  queryRecord() {
    return fetch(this.host + '/api/tenant/' , {
      redirect: 'follow'
    })
      .then(result => {
        if (!result.ok) {
          return reject(result.status);
        }
        return result.json();
      });
  }
});
