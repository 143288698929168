import SubscriptionAdapter from './subscription';

export default SubscriptionAdapter.extend({
  pathForType() {
    return 'v1/subscriptions/events'
  },
  buildEventItemsQuery(url, query) {
    let queryDetails = [];

    if (query.page) {
      queryDetails.push(`page=${query.page}`);
    }
    if (query.page_number) {
      queryDetails.push(`page_number=${query.page_number}`);
    }
    if (query.page_size) {
      queryDetails.push(`page_size=${query.page_size}`);
    }
    if (!query.state) {
      queryDetails.push('filter[subscriber_states.status.in]=1,2,3');
    }
    else {
      queryDetails.push(`filter[subscriber_states.status]=${query.state}`)
    }
    if (query.subscription) {
      queryDetails.push(`filter[subscription]=${query.subscription}`)
    }
    if (query.after_date) {
      queryDetails.push(`filter[first_timestamp.gte]=${query.after_date}`)
    }
    if (query.before_date) {
      queryDetails.push(`filter[first_timestamp.lte]=${query.before_date}`)
    }
    if (query.sort) {
      queryDetails.push(`sort=${query.sort}`);
    }
    if (query.remove_sub_type) {
      queryDetails.push(`exclude[subscription__type.exact]=${query.remove_sub_type}`)
    }
    if (query.include) {
      queryDetails.push(`include=${query.include}`)
    }
    if (query['filter[subscription.type]']) {
      queryDetails.push(`filter[subscription.type]=${query['filter[subscription.type]']}`);
    }

    return url + '?' + queryDetails.join('&');
  },

  query(store, type, query) {
    let url = this.buildURL(type, null, null);
    const endOfPath = url.lastIndexOf('/') + 1;
    url = url.slice(0, endOfPath);
    url = this.buildEventItemsQuery(url, query);
    const options = {headers: this.headers};
    return this.ajax(url, 'GET', options);
  }
});
