import Controller from '@ember/controller';
import { computed } from '@ember/object';
import moment from 'moment';
const CHART_OPTIONS = {
  global: {
    useUTC: false
  },
  time: {
    timezone: moment.tz.guess()
  },
  chart: {
    type: 'line',
    resetZoomButton: {
      theme: {
        fill: 'white',
        states: {
          hover: {
            fill: 'white'
          }
        }
      }
    },
    // Zoom on pinch
    zoomType: 'x',
    time: {
      timezone: moment.tz.guess()
    }
  },
  title: {
    text: ''
  },
  xAxis: {
    type: 'datetime',
    title: {
      text: 'Date ( ' + moment.tz.guess() + ' )'
    },
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S.%L',
      second: '%l:%M:%S',
      minute: '%l:%M %p',
      hour: '%l:%M %p',
      day: '%e. %b',
      week: '%e. %b',
      month: '%b \'%y',
      year: '%Y'
    }
  },
  yAxis: {
    title: {
      text: 'Readings'
    }
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true
      }
    }
  },
  legend: {
    enabled: true
  },
  navigator: {
    enabled: false
  },
  tooltip: {
    valueDecimals: 2
  }
};

export default Controller.extend({
  queryParams: ['datalogger', 'to', 'from'],
  datalogger: '',
  chartData: null,
  min: computed('from', function() {
    return moment(this.from).format('x');
  }),
  max: computed('to', function() {
    return moment(this.to).format('x');
  }),
  to: moment()
    .add(1, 'hour')
    .format(),
  from: moment()
    .subtract(2, 'day')
    .format(),
  toDate: computed('to', function() {
    return moment(this.to).format('YYYY-MM-DD');
  }),
  fromDate: computed('from', function() {
    return moment(this.from).format('YYYY-MM-DD');
  }),
  chartOptions: CHART_OPTIONS,
  init() {
    const controller = this;
    this.chartOptions.plotOptions.series = {
      point: { events: { click: controller.clickChart.bind(controller) } }
    };
    this._super(...arguments);
  },
  clickChart(event) {
    if (this.model && this.model.channel) {
      // Get the point that was clicked
      const point = event.point;
      // Get the time
      const datetime = moment(point.x).format();

      this.transitionToRoute('site.review', this.get('model.site.id'), {
        queryParams: {
          channel: this.get('model.channel.id'),
          to: datetime
        }
      });
    }
  },
  reloadChart() {
    let data = [];
    const min = this.min;
    const max = this.max;
    this.get('model.registers').forEach(register => {
      data.push({
        name: register.get('name') + ' (' + register.get('units') + ')',
        data: register
          .get('datapoints')
          .map(item => {
            const timestamp = item.get('timestamp');
            if (timestamp < min || timestamp > max) {
              return false;
            }
            return [item.get('timestamp'), item.get('truncatedValue')];
          })
          .filter(obj => obj != false)
          .sort((obj1, obj2) => {
            return obj1[0] - obj2[0];
          })
      });
    });
    this.set('chartData', data);
  },
  reloadData() {
    this.store
      .query('datapointvalue', {
        slug: this.get('model.slug'),
        to: this.to,
        from: this.from,
        output: 'datalist'
      })
      .then(() => {
        this.reloadChart();
      });
  },
  actions: {
    changeToDate(date) {
      this.set(
        'to',
        moment(date)
          .utc()
          .format()
      );
      this.reloadData();
    },
    clearToDate() {
      this.set(
        'to',
        moment()
          .utc()
          .format()
      );
      this.reloadData();
    },
    changeFromDate(date) {
      this.set(
        'from',
        moment(date)
          .utc()
          .format()
      );
      this.reloadData();
    },
    clearFromDate() {
      this.set(
        'from',
        moment()
          .subtract(1, 'day')
          .utc()
          .format()
      );
      this.reloadData();
    }
  }
});
