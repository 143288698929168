import Service from '@ember/service';
import ENV from '../config/environment';
import { computed } from '@ember/object';
import Ember from 'ember';
import RSVP from 'rsvp';

import { inject as service } from '@ember/service';

const ALERT_ENDPOINT = 'sockets/new-events'

export default Service.extend({
  websockets: service(),
  session: service(),
  tenant: service(),
  store: service(),
  socket: null,
  newAlertCount: 0,
  newState: null,
  viewedState: null,
  flaggedState: null,
  archivedState: null,
  alertType: null,
  inspectionType: null,
  flirType: null,
  lastUpdatedAlert: null,
  host: computed('tenant.subdomain', function() {
    let subdomain = this.get('tenant.subdomain')
    return ENV.vhiWsProtocol + '://' + subdomain + '.' + ENV.vhiServer;
  }),
  newAlertsSummary: computed('newAlertCount', function() {
    let total = this.newAlertCount;
    if (total > 100) {
      return '100+';
    }
    return total;
  }),

  init() {
    this._super(...arguments);
    let store = this.store;
    let subService = this;
    this.set('ready', RSVP.hash({
      eventstate: store.findAll('subscription/event-state-type').then(results => {
        results.forEach(state => {
          if (state.name === 'New') {
            subService.set('newState', state);
          } else if (state.name === 'Viewed') {
            subService.set('viewedState', state);
          } else if (state.name === 'Flagged') {
            subService.set('flaggedState', state);
          } else if (state.name === 'Archived') {
            subService.set('archivedState', state);
          }
        });
        return results;
      }),
      subtype: store.findAll('subscription/subscription-type').then(results => {
        results.forEach(type => {
          if (type.name === 'alert') {
            subService.set('alertType', type);
          } else if (type.name === 'inspection') {
            subService.set('inspectionType', type);
          } else if (type.name === 'flir') {
            subService.set('flirType', type);
          }
        })
        return results;
      })
    }));
    if (!Ember.testing) {
      this.connectWebsocket();
    }
  },
  buildURL() {
    let path;
    let url = [];
    let host = this.host;
    url.push(host);
    path = ALERT_ENDPOINT;
    url.push(path);

    url = url.join('/');
    if (!host && url && url.charAt(0) !== '/') {
      url = '/' + url;
    }

    url = url + '/';
    let access_token = this.get('session.data.authenticated.access_token');
    url = `${url}?access_token=${access_token}`;
    return url;

  },
  connectWebsocket() {
    let url = this.buildURL();
    const socket = this.websockets.socketFor(url);
    socket.on('message', this.onMessage, this);
    socket.on('close', this.onClose, this);
    this.set('socket', socket);
  },
  onMessage(message) {
    const messageData = JSON.parse(message.data);
    if (messageData.status) {
      if (messageData.status === 'failed') {
        this.socket.close();
      }

    } else {
      if (!(this.isDestroyed || this.isDestroying)) {
        this.set('newAlertCount', messageData.total_count);
        this.set('lastUpdatedAlert', messageData.event_id);
      }
    }
  },
  onClose() {
    let url = this.buildURL();
    const socket = this.websockets.socketFor(url);
    socket.reconnect();
  }
});
