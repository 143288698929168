import DS from 'ember-data';
import { computed } from '@ember/object'
import { memberAction } from 'ember-api-actions';

export const EDGE_CHANNEL = 'edge channel';
export const SPYPOINT_CHANNEL = 'spypoint channel';
export const RECONYX_CHANNEL = 'reconyx channel';

export const PEOPLE_GROUP_NAME = 'person';
export const VEHICLE_GROUP_NAME = 'vehicle';
export const ANIMAL_GROUP_NAME = 'animal'

export const CHANNEL_GROUP_FILTER_MAP = {
  [VEHICLE_GROUP_NAME]: {iconText: 'car'},
  [PEOPLE_GROUP_NAME]: {iconText: 'users'},
  [ANIMAL_GROUP_NAME]: {iconText: 'paw'}
};

const DEFAULT_ACTION_OPTIONS = {
  // it doesn't work here,
  // JSON-API adapter bug or ember-api-actions bug
  // ajaxOptions: {
  //   headers: {
  //     'Accept': 'application/vnd.api+json, application/json', 'Content-Type': 'application/vnd.api+json',
  //   },
  // },
  before(attributes) {
    let payload = this.serialize();

    payload = {
      data: {
        type: 'ChannelPosition',
        id: this.id,
        attributes: attributes
      }
    };

    return payload;
  }
}

export default DS.Model.extend({
  adjunctNode: DS.attr(),
  bandwidthControlLevel: DS.attr(),
  cameraState: DS.attr(),
  cameraState_date: DS.attr(),
  channelType: DS.attr(),
  cloudbridge: DS.attr(), //"171219-an-7cf8"
  continuousRecording: DS.attr(),
  cvFeatures: DS.hasMany('tag-type-group'),
  deployState: DS.attr(),
  disableChecks: DS.attr(),
  disabled: DS.attr(),
  gateway: DS.attr(),
  hlsUrl: DS.attr(),
  lastPolledSnapshot: DS.attr(),
  latestImage: DS.belongsTo('image'),// {id: 578033, description: null, original_name: "171222-ch-e376-2018-05-07_17-50-17629180.jpg",…}
  legacyCloudbridge: DS.attr(),
  maintenanceMode: DS.attr(),
  maxEventDuration: DS.attr(),
  maxEventGapTime: DS.attr(),
  mute: DS.attr(),
  name: DS.attr(),
  order: DS.attr(),
  profile: DS.attr(),
  ptzPresets: DS.hasMany('ptz-preset'),
  rtmpUrl: DS.attr(),
  site: DS.belongsTo('site'),
  slug: DS.attr(),
  subFilters: DS.hasMany('subscription/query-doc-filter'),
  imageType: DS.attr(),
  useSiteAlertRule: DS.attr(),
  sortingCVFeaturesParams: Object.freeze(['name:asc']),
  sortedCVFeatures: computed.sort('cvFeatures', 'sortingCVFeaturesParams'),

  sortedCVTags: computed('cvFeatures', function() {
    let tags = [];
    if (this.sortedCVFeatures) {
      this.sortedCVFeatures.forEach(cvFeature => {
        let featureTags = [];
        cvFeature.tagTypes.forEach(cvTag => featureTags.push(cvTag.name));
        tags.push(...featureTags.sort());
      });
    }

    return tags;
  }),

  CVTagsConfig: computed('cvFeatures', function() {
    let tagsConfig = {};
    if (!this.cvFeatures) {
      return tagsConfig;
    }

    this.cvFeatures.forEach(cvFeature => {
      let cvFeatureConfig = CHANNEL_GROUP_FILTER_MAP[cvFeature.name];

      if (cvFeatureConfig) {
        cvFeature.tagTypes.forEach(cvTag => {
          tagsConfig[cvTag.name] = cvFeatureConfig;
        });
      }
    });

    return tagsConfig;
  }),

  movePTZ: memberAction(Object.assign({
    path: '',
    urlType: 'moveRecord',
    type: 'patch'}, DEFAULT_ACTION_OPTIONS)
  ),

  zoom: memberAction(Object.assign({
    path: 'zoom/',
    urlType: 'zoomRecord',
    type: 'patch'}, DEFAULT_ACTION_OPTIONS)
  ),

  isFeatureEnabled(feature) {
    if (this.cvFeatures && this.cvFeatures.findBy('name', feature)) {
      return true;
    }
    return false;
  },

  ptzEnabled: computed('ptzPresets', function() {
    return (this.ptzPresets && this.ptzPresets.length > 0);
  }),
  isEdgeCamera: computed('channelType', function() {
    const channelType = this.channelType ? this.channelType.toLowerCase() : null
    return (
      channelType === EDGE_CHANNEL ||
      channelType === SPYPOINT_CHANNEL ||
      channelType === RECONYX_CHANNEL
    );
  })


});
