import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import constants from '../../../utils/ui-constants'

const { alias } = computed;
const { UI_DATE_FORMAT } = constants;

export default Component.extend({
  classNames: ['manage-list-item'],
  classNameBindings: ['isNew:is-new'],
  listDateFormat: UI_DATE_FORMAT,
  eventReport: service(),
  featureFlags: service(),
  subscription: service(),
  model: null,
  isDownloadPdfEnabled: alias('featureFlags.isDownloadPdfEnabled'),

  isNew: computed('model.currentStatus', function() {
    return this.get('model.currentStatus.status.id') === this.subscription.newState.id
  }),

  isPending: computed('model', 'eventReport.pendingReports', function() {
    return this.eventReport.pendingReports && this.model
      ? this.eventReport.isPending(this.model.id)
      : false;
  }),

  actions: {
    downloadReport() {
      this.eventReport.getReportUrl(this.model.id);
    },
  }
});
