import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { REVIEWSTREAM_FILTER_APPLIED } from '../services/analytics';
import { isArray } from '@ember/array';


const TAG_VEHICLE = 'vehicle';
const TAG_PERSON = 'person';
const TAG_ANIMAL = 'animal';

const MEDIA_VIDEO = 'video';

const REASON_MOTION = 'activity';
const REASON_USER_REQUESTED = 'refresh';
const REASON_TIME_LAPSE = 'automated';
const REASON_INSPECTION = 'inspection';

const REMOVED = 'removed';
const ADDED = 'added';

export default Component.extend({
  classNames: ['reach-image-filter', 'collapse'],
  to: null,
  from: null,
  channels: null,
  analytics: service(),

  isVideoSelected: computed('media', function() {
    return this.media === MEDIA_VIDEO;
  }),

  isMotionSelected: computed('reasons', 'reasons.[]', function() {
    return this.reasons && this.reasons.indexOf(REASON_MOTION) >= 0;
  }),
  isUserRequestedSelected: computed('reasons', 'reasons.[]', function() {
    return this.reasons && this.reasons.indexOf(REASON_USER_REQUESTED) >= 0;
  }),
  isTimeLapseSelected: computed('reasons', 'reasons.[]', function() {
    return this.reasons && this.reasons.indexOf(REASON_TIME_LAPSE) >= 0;
  }),
  isInspectionSelected: computed('reasons', 'reasons.[]', function() {
    return this.reasons && this.reasons.indexOf(REASON_INSPECTION) >= 0;
  }),
  isAllReasonsActive: computed('reasons.[]', 'media', function () {
    const reasons = this.reasons;
    if (reasons && reasons.length > 0) {
      return false;
    }
    if (this.media === MEDIA_VIDEO) {
      return false;
    }
    return true;
  }),

  addItemToFilter(item, filter, type) {
    if (!filter) {
      filter = [];
    }
    if (!isArray(filter)) {
      filter = [filter]
    }

    const found = filter.indexOf(item);
    if (found >= 0) {
      filter.removeAt(found, 1);
      if (filter.length === 0) {
        filter = null;
      }
      this.analytics.trackMultiple(REVIEWSTREAM_FILTER_APPLIED, {
        type: type,
        action: REMOVED
      });
    } else {
      filter.pushObject(item);
      this.analytics.trackMultiple(REVIEWSTREAM_FILTER_APPLIED, {
        type: type,
        action: ADDED
      });
    }
    return filter;
  },
  addTag(tag) {
    let tags = this.tags;
    tags = this.addItemToFilter(tag, tags, tag);
    this.set('tags', tags);
  },
  addTags(tags) {
    let currentTags = [];
    for (const tag of tags) {
      currentTags = this.addItemToFilter(tag, currentTags, tag);
    }
    this.set('tags', currentTags);
  },
  addReason(reason) {
    let reasons = this.reasons;
    reasons = this.addItemToFilter(reason, reasons, reason);
    this.set('reasons', reasons);
  },
  updateChannel(id) {
    let channels = this.selectedChannelIDs;
    channels = this.addItemToFilter(id, channels, 'Channel');
    this.set('selectedChannelIDs', channels);
  },
  actions: {
    fromDateChanged(date) {
      this.fromDateChanged(date);
      this.analytics.trackMultiple(REVIEWSTREAM_FILTER_APPLIED, {
        type: 'From Time',
        action: ADDED
      });
    },
    toDateChanged(date) {
      this.toDateChanged(date);
      this.analytics.trackMultiple(REVIEWSTREAM_FILTER_APPLIED, {
        type: 'To Time',
        action: ADDED
      });
    },
    addChannel(channelId) {
      this.updateChannel(channelId);
      this.set('selectAllChannelsOnInit', false);
    },
    removeChannel(channelId) {
      this.updateChannel(channelId);
      this.set('selectAllChannelsOnInit', false);
    },

    clickAllObjectTags() {
      if (this.tags) {
        this.set('tags', null);
      }
      this.set('media', null);
    },
    clickVehicle() {
      this.addTag(TAG_VEHICLE);
      this.set('media', null);
    },
    selectTags(tags) {
      this.addTags(tags);
      this.set('media', null);
    },
    clickPeople() {
      this.addTag(TAG_PERSON);
      this.set('media', null);
    },
    clickAnimals() {
      this.addTag(TAG_ANIMAL);
      this.set('media', null);
    },
    clickVideo() {
      if (this.media !== MEDIA_VIDEO) {
        this.set('media', MEDIA_VIDEO);
        this.analytics.trackMultiple(REVIEWSTREAM_FILTER_APPLIED, {
          type: 'Videos',
          action: ADDED
        });

        if (this.tags) {
          this.set('tags', null);
        }
        if (this.reasons) {
          this.set('reasons', null);
        }

      } else {
        this.set('media', null);
        this.analytics.trackMultiple(REVIEWSTREAM_FILTER_APPLIED, {
          type: 'Videos',
          action: REMOVED
        });
      }
    },

    clickAllReasons() {
      if (this.reasons) {
        this.set('reasons', null)
      }
      this.set('media', null);
    },
    clickMotion() {
      this.addReason(REASON_MOTION);
      this.set('media', null);
    },
    clickUserRequested() {
      this.addReason(REASON_USER_REQUESTED);
      this.set('media', null);
    },
    clickTimeLapse() {
      this.addReason(REASON_TIME_LAPSE);
      this.set('media', null);
    },
    clickInspection() {
      this.addReason(REASON_INSPECTION);
      this.set('media', null);
    },
  }
});
