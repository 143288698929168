import Component from '@ember/component';
import AlertManipulator from '../mixins/alert-manipulator';
import { computed } from '@ember/object';

export default Component.extend(AlertManipulator, {
  classNameBindings: ['topLevelClass', 'alertId'],
  topLevelClass: 'subscription-event-preview',
  alertId: computed('model.id', function() {
    return 'alert-id-' + this.model.id;
  }),
  click () {
    this.viewEvent();
    return true;
  },
  contextMenu() {
    this.viewEvent();
    return true;
  },

});
