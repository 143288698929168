import DS from 'ember-data';
import EventItemContent from './subscription/event-item-content';
import { isIos } from '../utils/responsive';
import {inject as service} from '@ember/service';

const IMAGE_HEADERS = {
  'Content-Type': 'image/jpeg',
  'Cache-Control': 'no-cache',
  'Accept': '*/*'
};



export default EventItemContent.extend({
  site: DS.belongsTo('site', {inverse: null, async: true}),
  size: DS.attr(),
  source: DS.attr(),
  channel: DS.belongsTo('channel', {inverse: null, async: true}),
  mediaType: '',
  session: service(),

  downloadUrl() {
    return '';
  },
  download() {
    // Set headers to the request

    let headers = IMAGE_HEADERS;
    const url = this.downloadUrl;
    //if we're using the old image server then don't add the auth header
    if (url.indexOf('images.ospreyreach.com') <= 0) {
      let { access_token } = this.get('session.data.authenticated');
      headers['Authorization'] = `Bearer ${access_token}`;
    }
    if(this.mediaType.includes('pdf')) {
      delete headers['Content-Type'];
      delete headers['Authorization'];
    }
    return fetch(url,
      {
        method: 'GET',
        cache: 'no-cache',
        headers,
        responseType: 'arraybuffer'
      })
      .then((data) => {
        if (!data.ok) {
          throw ('Error downloading');
        }

        // Create the blob for and set the type depending on the media type

        //ie
        if (data._bodyBlob) {
          this.saveBlob(data._bodyBlob);
          return;
        }
        if (data.body.constructor.name === 'ReadableStream') {
          this.readAllChunks(data.body)
            .then(chunks => {
              const blob = new Blob(chunks, { type: this.mediaType });
              this.saveBlob(blob);
            })
            .catch(() => {throw ('Error downloading')});
        }
      })
  },
  readAllChunks(readableStream) {
    const reader = readableStream.getReader();
    const chunks = [];

    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          return chunks;
        }
        chunks.push(value);
        return pump();
      });
    }

    return pump();
  },
  saveBlob(blob) {
    const urlCreator = window.URL || window.webkitURL;

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, this.userFriendlyDownloadName);
    } else if (isIos()) {
      // opening PDF in iOS from: https://stackoverflow.com/questions/39375076/how-to-display-pdf-blob-on-ios-sent-from-my-angularjs-app
      window.location.href = urlCreator.createObjectURL(blob);
    } else {
      const fileURL = urlCreator.createObjectURL(blob);

      const save = document.createElement('a');
      save.href = fileURL;
      save.target = '_blank';
      save.download = this.userFriendlyDownloadName;

      const event = document.createEvent('MouseEvents');
      event.initMouseEvent(
        'click', true, false, window, 0, 0, 0, 0, 0,
        false, false, false, false, 0, null
      );

      save.dispatchEvent(event);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

});
