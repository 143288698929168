import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import move from 'ember-animated/motions/move';
import ErrorHandler from '../../mixins/subscription-edit-error-handler';
import { filterBy, sort } from '@ember/object/computed';
import { ALERT_EDIT_SAVED, ALERT_EDITOR_OPENED } from '../../services/analytics';

export default Controller.extend(ErrorHandler, {
  currentUser: service(),
  store: service(),
  analytics: service(),
  allUsers: service(),
  errors: null,
  showSitePicker: false,
  selectedChannelIDs: null,
  isSaving: false,
  cancelClicked: false,
  saveClicked: false,
  activeSubscriptionFilters: filterBy(
    'model.subscriptionFilters',
    'isDeleted',
    false
  ),
  activeSubscribers: filterBy('model.subscribers', 'isDeleted', false),
  notificationSorting: Object.freeze(['name:asc']),
  arrangedNotificationTypes: sort('notificationTypes', 'notificationSorting'),

  init() {
    this._super(...arguments);
    this.selectedChannelIDs = this.selectedChannelIDs || [];
    this.errors = [];
    this.analytics.trackMultiple(ALERT_EDITOR_OPENED, {
      subscriptionId: this.get('model.id'),
      subscriptionName: this.get('model.name')
    });
  },
  currentErrors: computed('errors.[]', function() {
    return this.errors;
  }),
  transition: function({ insertedSprites, keptSprites, removedSprites }) {
    for (let sprite of insertedSprites) {
      sprite.startAtPixel({ y: -750 });
      move(sprite, { duration: 750 });
    }

    for (let sprite of keptSprites) {
      move(sprite, { duration: 750 });
    }

    for (let sprite of removedSprites) {
      sprite.endAtPixel({ y: -750 });
      move(sprite, { duration: 750 });
    }
  },
  saveSubscriptionRelationships: function(subscription) {
    return Promise.all([
      ...subscription.subscriptionFilters.map(item => {
        if (item.hasDirtyAttributes) {
          item.subscriptionId = subscription.id;
          return item.save();
        }
      }),
      ...subscription.subscribers.map(item => {
        return item
          .save()
          .then(() => item.notificationMethods.map(method => method.save()));
      }),
      ...subscription.schedules.map(item => item.save())
    ]);
  },

  actions: {
    saveSubscription() {
      const clientErrors = this.getErrorsOnSubscription(this.model);
      if (clientErrors && clientErrors.length > 0) {
        this.errors.pushObjects(clientErrors);
        return;
      }
      this.analytics.trackMultiple(ALERT_EDIT_SAVED, {
        subscriptionId: this.get('model.id'),
        subscriptionName: this.get('model.name')
      });
      const modelId = this.model.get('id');
      if (modelId) {
        this.set('isSaving', true);
        this.set('saveClicked', true);

        this.saveSubscriptionRelationships(this.model)
          .then(() => {
            return this.model.save({adapterOptions: {queryParams: {context: 'alert'}}});
          })
          .then(() => {
            this.set('isSaving', false);
            this.transitionToRoute('alert.manage');
          })
          .catch(error => {
            this.set('isSaving', false);
            const translatedErrors = this.translateServerErrorsToClient(error);
            this.errors.pushObjects(translatedErrors);
          });
      } else {
        this.model
          .save()
          .then(subscription =>
            this.saveSubscriptionRelationships(subscription)
          )
          .then(() => {
            this.set('isSaving', false);
            this.transitionToRoute('alert.manage');
          })
          .catch(error => {
            this.set('isSaving', false);
            const translatedErrors = this.translateServerErrorsToClient(error);
            this.errors.pushObjects(translatedErrors);
          });
      }
    },
    addUserToSubscription(user) {
      const alreadySubscribed = this.model.subscribers.find(subscriber => subscriber.user.get('id') === user.get('id'));
      if (alreadySubscribed && alreadySubscribed.isDeleted) {
        alreadySubscribed.rollbackAttributes();
        return;
      }
      const newSub = this.store.createRecord('subscription/subscriber', {
        user: user,
        allowEdit: false,
        subscription: this.model
      });
      this.notificationTypes.forEach(type => {
        if (type.visible) {
          let method = this.store.createRecord(
            'subscription/notification-method',
            {
              enabled: true,
              subscriber: newSub,
              notificationMethodType: type
            }
          );
          newSub.notificationMethods.pushObject(method);
        }
      });
      this.model.subscribers.pushObject(newSub);
    },
    toggleNotificationMethod(subscriber, type, shouldToggleOn) {
      const found = subscriber.notificationMethods.find(
        notificationMethod =>
          notificationMethod.get('notificationMethodType.id') === type.get('id')
      );
      if (found) {
        found.set('enabled', shouldToggleOn);
      } else {
        if (shouldToggleOn) {
          this.store.createRecord('subscription/notification-method', {
            enabled: shouldToggleOn,
            subscriber: subscriber,
            notificationMethodType: type
          });
        }
      }
    },
    removeUserFromSubscription(subscriber) {
      if (
        this.model.get('creator.id') !== subscriber.user.get('id') ||
        this.currentUser.vhiUser.get('id') !== subscriber.user.get('id')
      ) {
        subscriber.deleteRecord();
      }
    },
    clearErrors() {
      this.set('errors', []);
    },
    resetFilters() {
      this.model.subscriptionFilters.map(item => {
        item.deleteRecord();
        return item;
      });
    },
    addSiteSelection(filters) {
      this.set('model.subscriptionFilters', filters);
      return Promise.resolve();
    },
    removeFilters(filters) {
      filters.map(item => {
        item.deleteRecord();
        return item;
      });
    },
    resetDirtySubscription() {
      this.cancelClicked = true;

      // NOTE: uncommitted dirty model is cleaned up in route.resetController()
      this.transitionToRoute('alert.manage');
    },
    toggleShowSitePicker() {
      this.set('showSitePicker', !this.showSitePicker);
    },
    createSubFiltersFromCVFeatures(channel, ptz) {
      return this.store.createRecord('subscription/query-doc-filter', {
        tags: channel.sortedCVTags,
        channel: channel,
        ptzPreset: ptz,
        site: channel.site
      });
    }
  }
});
