import DS from 'ember-data';

export default DS.Model.extend({
  channel: DS.belongsTo('channel'),
  enabled: DS.attr(),
  lastPolled: DS.attr(),
  lastSuccessfulPoll: DS.attr(),
  name: DS.attr(),
  pollingFrequency: DS.attr(),
  registers: DS.hasMany('register'),
  site: DS.belongsTo('site'),
  slug: DS.attr(),
  type: DS.attr(),
  inSync: DS.attr(),
});
