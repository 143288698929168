import Component from '@ember/component';

export default Component.extend({
  classNames: ['inspection-schedule-picker_container'],
  disabled: false,
  actions: {
    createInspectionSchedule() {
      this.createInspectionSchedule();
    },
    saveSchedule(schedule) {
      // NOTE: schedule object is not saved until the entire inspection is persisted in one call
      return schedule;
    },
    deleteSchedule(schedule) {
      schedule.deleteRecord();
      return this.schedules.removeObject(schedule);
    }
  }
});
