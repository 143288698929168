import DS from 'ember-data';
import { computed, observer } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';
import MediaModel from './media';

const REASON_REFRESH = 'refresh';
const REASON_ACTIVITY = 'activity';
const REASON_AUTOMATED = 'automated';
const REASON_INSPECTION = 'inspection';
const NIGHT_MODE = 'night mode';
const META = 'meta;'
const MIN_DISPLAYED_CONFIDENCE = 0.45;
const MIN_DISPLAYED_NIGHTMODE_CONFIDENCE = 0.25;

const IMAGE_SIZES = {
  small: 256,
  medium: 512,
  large: 1024,
};

const IMAGE_HEADERS = {
  'Accept': 'image/jpeg',
  'Content-Type': 'image/jpeg'
};

const logger = console;

export default MediaModel.extend({
  init() {
    this._super(...arguments);
    this.initDuration();
  },
  session: service(),
  intl: service(),
  store: service(),
  reason: DS.attr(),
  channelId: DS.attr(),
  siteId: DS.attr(),
  coordinates: DS.attr(),
  description: DS.attr(),
  fullImageUrl: DS.attr(),
  originalName: DS.attr(),
  //of course VHI uses the preset number instead of the id...
  ptzPreset: DS.belongsTo('ptz-preset'),
  resizableImageUrl: DS.attr('string'),
  tagSet: DS.attr(),
  user: DS.attr(),
  mediaType: 'image/jpeg',
  /**
   * This is a workaround while were are blending together JSONAPI
   * requests and non-json api requests. the "hack" around the channel and
   * site needs to be properly fixed at a vhi level, which probably makes
   * sense to happen when we convert these endpoinst to jsonapi.
   * richardb - Sept, 2019
   **/
  peekChannel: computed('channel', 'channelId', function() {
    if (this.channel.get('id')) {
      return this.channel;
    }
    if (!this.channelId) {
      return null;
    }
    return this.store.peekRecord('channel', this.channelId)
  }),
  peekSite: computed('siteId', function() {
    if (this.site.get('id')) {
      return this.site;
    }
    if (!this.siteId) {
      return null;
    }
    return this.store.peekRecord('site', this.siteId)
  }),

  realReason: computed('reason', function() {
    return this.reason;
  }),
  downloadUrl: computed('fullImageUrl', function() {
    return this.fullImageUrl;
  }),

  /**
   * take the image url provided by vhi and replace the width/height params with
   * the appropriate requested size while preserving the images aspect ratio
   * Image will be returned with the appropriate width and aspect friendly height
   *
   * imgHeight is legacy from the old image server, the lambda based ones takes aspect
   * ratio into account and only needs a width provided (and height will not exist
   * on vhi image urls)
   *
   * @param size
   * @returns {*}
   */
  getResizedImageURL(size) {
    if (!this.resizableImageUrl) {
      return ''
    }
    return this.resizableImageUrl.replace('${imgWidth}', IMAGE_SIZES[size])
      .replace('${imgHeight}', IMAGE_SIZES[size]);
  },
  /**
   * gets the blob representation of the image from the image server
   * @param url
   * @returns {Promise<string | void>|null}
   */
  getImageBlob: function(url) {
    if (!url) {
      return null;
    }

    let { access_token } = this.get('session.data.authenticated');
    let headers = IMAGE_HEADERS;
    //if we're using the old image server then don't add the auth header
    if (url.indexOf('images.ospreyreach.com') <= 0) {
      headers['Authorization'] = `Bearer ${access_token}`;
    }

    return fetch(url, { headers: headers })
      .then(response => {
        const reader = response.body.getReader();

        return new ReadableStream({
          start(controller) {
            return pump();

            function pump() {
              return reader.read()
                .then(({ done, value }) => {
                  // When no more data needs to be consumed, close the stream
                  if (done) {
                    controller.close();
                    return;
                  }
                  // Enqueue the next data chunk into our target stream
                  controller.enqueue(value);
                  return pump();
                });
            }
          }
        })
      })
      .then(stream =>
        new Response(stream)
      )
      .then(response =>
        response.blob()
      )
      .then(blob => URL.createObjectURL(blob))
      .catch(reason =>
        logger.log(reason)
      )
  },

  getResizedImageBlob(size) {
    /**
     * Retrieve an image from the image server, sized as specified by the size argument.
     *
     * @type {string}
     */

    const url = this.getResizedImageURL(size);

    return this.getImageBlob(url);
  },

  smallImage: computed('resizableImageUrl', function() {
    return this.getResizedImageURL('small');
  }),

  mediumImage: computed('resizableImageUrl', function() {
    return this.getResizedImageURL('medium');
  }),

  largeImage: computed('resizableImageUrl', function() {
    return this.getResizedImageURL('large');
  }),

  smallImageBlob: computed('resizableImageUrl', async function() {
    return this.getResizedImageBlob('small');
  }),

  mediumImageBlob: computed('resizableImageUrl', async function() {
    return this.getResizedImageBlob('medium');
  }),

  largeImageBlob: computed('resizableImageUrl', async function() {
    return this.getResizedImageBlob('large');
  }),

  fullImageBlob: computed('fullImageUrl', async function() {
    return this.getImageBlob(this.fullImageUrl);
  }),

  reasonText: computed('reason', function() {
    const reason = this.reason;
    return this.intl.t('site.details.' + reason);
  }),

  reasonIcon: computed('reason', function() {
    const reason = this.reason;
    if (reason === REASON_REFRESH) {
      return 'user';
    } else if (reason === REASON_ACTIVITY) {
      return 'cog';
    } else if (reason === REASON_AUTOMATED) {
      return 'clock';
    } else if (reason === REASON_INSPECTION) {
      return 'clipboard-list';
    }
    return 'video';
  }),

  duration: '',
  currentPtz: computed('channel', 'channelId', 'ptzPreset', function() {
    if (!this.channel) {
      return null;
    }
    let positions = this.get('channel.ptzPresets');
    if (!positions || positions.length === 0) {
      return null
    }

    let found = positions.findBy('number', this.ptzPreset.get('number'));
    if (!found) {
      return null;
    }
    return found;
  }),

  updateDuration() {
    const timestamp = this.timestamp;
    if (!timestamp) {
      this.set('duration', this.intl.t('site.details.never'));
    } else {
      this.set('duration', moment.tz(timestamp, this.timezone)
        .fromNow());
    }
  },

  watchDuration: observer('timestamp', function() {
    this.updateDuration();
  }),

  initDuration() {
    this.updateDuration();
    const timeout = setInterval(() => {
      if (this.isDestroyed || this.isDestroying) {
        clearInterval(timeout);
        return;
      }
      this.updateDuration();
    }, 30000);

  },

  displayLabels: computed('tagSet', function() {
    let tags = this.tagSet;

    if (!tags) {
      return null;
    }
    // is there a nightmode image
    let nightMode = false;
    tags.forEach((tag) => {
      if (tag.labels[0] === NIGHT_MODE) {
        nightMode = true;
      }
    });
    let displayLabels = [];
    tags.forEach((tag) => {
      if (tag.category != META) {
        let minConfidence = MIN_DISPLAYED_CONFIDENCE;
        if (nightMode) {
          minConfidence = MIN_DISPLAYED_NIGHTMODE_CONFIDENCE;
        }
        if (tag.confidence > minConfidence) {
          const label = tag.labels[0]
          if (displayLabels.indexOf(label) < 0) {
            displayLabels.addObject(label);
          }
        }
      }
    });
    return displayLabels;
  }),


  userFriendlyDownloadName: computed('isoTimestamp', 'channel.name', function() {
    let cameraName = this.get('channel.name');
    cameraName = cameraName ? cameraName : '';
    cameraName = cameraName.toLowerCase()
      .replace(new RegExp(' ', 'g'), '_');
    const timestamp = this.isoTimestamp;
    let fileName = `${cameraName}_${timestamp}.jpg`;
    return fileName.replace(new RegExp(':', 'g'), '_');

  }),

})
;
