import DS from 'ember-data';
import BasicUser from './basic-user';

export default BasicUser.extend({
  dateJoined: DS.attr(),
  intercon: DS.attr(),
  isStaff: DS.attr(),
  isSuperuser: DS.attr(),
  lastLogin: DS.attr(),
  permissions: DS.attr(),
  phoneNumber: DS.attr(),
  positionTitle: DS.attr(),
  groups: DS.attr(),

  hasPermission(requestedPermission) {
    const permissions = this.permissions;
    if (!permissions) {
      return false;
    }
    return permissions.indexOf(requestedPermission) > -1;
  }
});
