import { dasherize, underscore } from '@ember/string';
import DS from 'ember-data';
import { generateRandomPoint } from '../utils/geo';
import moment from 'moment';

const VHI_RELATIONSHIPS = [
  'site',
  'site_id',
  'user',
  'latest_image',
  'channel',
  'channels',
  'registers',
  'register',
  'data_logger',
  'datapointname_set',
  'ptzpreset_set',
  'cv_features',
  'tag_types',
  'tag-type-group',
  'tag_type_group',
  'ptz-preset',
  'ptz_preset'
];


let NEW_ID = 0;
export default DS.JSONAPISerializer.extend({

  getRelationships() {
    return VHI_RELATIONSHIPS;
  },
  keyForAttribute(attr) {
    return underscore(attr);
  },
  normalizeItem(modelName, item, payload) {
    let newItem = {
      id: item.id,
      type: item._type ? item._type : modelName,
      attributes: {},
      relationships: {}
    };

    if (newItem.type === 'subscription/event') {
      newItem.attributes.last_update = moment();
    }
    if (newItem.type === 'media') {
      newItem.type = item.resizable_image_url ? 'image' : 'video';
    }

    if (!newItem.id) {
      NEW_ID = NEW_ID + 1;
      newItem.id = NEW_ID;
    }
    if (!isNaN(newItem.id)) {
      newItem.id = Number(newItem.id);
    }
    const relationships = this.getRelationships();
    for (var key in item) {

      if (relationships.indexOf(key) >= 0) {
        let newKey = dasherize(key);

        if (key === 'datapointname_set') {
          newKey = 'registers';
        } else if (key === 'site_id') {
          newKey = 'site';
        } else if (key === 'data_logger') {
          newKey = 'datalogger';
          item[key].id = item[key].id ? item[key].id : item[key].slug;
        } else if (key === 'ptzpreset_set') {
          newKey = 'ptz_positions';
        } else if (key === 'ptz_preset') {
          newKey = 'ptz_preset';
        } else if (key === 'cv_features') {
          newKey = 'cv_features';
        } else if (key === 'tag_types') {
          newKey = 'tag_types';
        }
        if (!newItem.relationships[newKey]) {
          if (Array.isArray(item[key])) {
            if (item[key].length <= 0) {
              continue;
            }
            newItem.relationships[newKey] = {data: []};
          } else {
            if (!item[key]) {
              continue;
            }
            newItem.relationships[newKey] = {data: {}};
          }
        }

        if (newKey === 'registers') {
          if (item[key].length > 0) {
            if (!Array.isArray(newItem.relationships[newKey].data)) {
              newItem.relationships[newKey].data = [];
            }
            if (!payload.included) {
              payload.included = [];
            }
            item[key].forEach(reg => {
              newItem.relationships[newKey].data.push({
                type: 'register',
                id: reg.id
              });
              payload.included.push(
                this.normalizeItem('register', reg, payload)
              );
            });
          }
        } else if (key === 'register' || key === 'data_logger') {
          if (!Array.isArray(newItem.relationships[newKey].data)) {
            newItem.relationships[newKey].data = [];
          }
          if (!payload.included) {
            payload.included = [];
          }
          newItem.relationships[newKey].data = {
            type: newKey,
            id: item[key].id
          };
          payload.included.push(this.normalizeItem(newKey, item[key], payload));
        } else if (key === 'latest_image') {
          if (item[key]) {
            newItem.relationships[newKey] = {
              // @TODO workaround to make it possible to work with old style serializers
              // @TODO remove it after we will migrate these serializer to JSON-API style
              data: { type: 'images', id: item[key].id || item[key] }
            };
            if (!payload.included) {
              payload.included = [];
            }
            payload.included.push(
              this.normalizeItem('image', item[key], payload)
            );
          }
        } else if (newKey === 'channels') {
          if (item[key].length > 0) {
            if (!Array.isArray(newItem.relationships[newKey].data)) {
              newItem.relationships[newKey].data = [];
            }
            if (!payload.included) {
              payload.included = [];
            }
            item[key].forEach(reg => {
              newItem.relationships[newKey].data.push({
                type: 'channel',
                id: reg.id
              });
              payload.included.push(
                this.normalizeItem('channel', reg, payload)
              );
            });
          }
        } else if (newKey === 'cv_features') {
          if (item[key].length > 0) {
            if (!Array.isArray(newItem.relationships[newKey].data)) {
              newItem.relationships[newKey].data = [];
            }
            if (!payload.included) {
              payload.included = [];
            }
            item[key].forEach(reg => {
              newItem.relationships[newKey].data.push({
                type: 'tag-type-group',
                id: reg.id
              });
              payload.included.push(
                this.normalizeItem('tag-type-group', reg, payload)
              );
            });
          }
        } else if (newKey === 'tag_types') {
          if (item[key].length > 0) {
            if (!Array.isArray(newItem.relationships[newKey].data)) {
              newItem.relationships[newKey].data = [];
            }
            if (!payload.included) {
              payload.included = [];
            }
            item[key].forEach(reg => {
              newItem.relationships[newKey].data.push({
                type: 'tag-type',
                id: reg.id
              });
              payload.included.push(
                this.normalizeItem('tag-type', reg, payload)
              );
            });
          }
        } else {
          if (Array.isArray(item[key])) {

            item[key].forEach(thing => {
              newItem.relationships[newKey].data.push({
                type: newKey,
                id: thing.id
              });
              if (!payload.included) {
                payload.included = [];
              }
              let modelType = newKey;
              if (modelType === 'ptz_positions') {
                modelType = 'ptz-preset';
              } else if (modelType === 'cv_features') {
                modelType = 'tag-type-group';
              }
              payload.included.push(
                this.normalizeItem(modelType, thing, payload)
              );
            });
          } else {
            if (key === 'channel' && item['channel_id']) {

              key = 'channel_id';
              newItem.relationships[newKey] = {
                data: {
                  type: newKey,
                  id: item[key]
                }
              };
            } else if (newKey === 'subscription' || newKey === 'event') {
              newItem.relationships[newKey] = {
                data: {type: 'subscription/' + newKey, id: item[key]}
              };
            } else if (key === 'user') {
              newItem.relationships[newKey] = {data: {type: newKey, id: item[key].id}};
              payload.included.push(this.normalizeItem(key, item[key], payload));
            } else if (newKey === 'ptz_preset') {
              newItem.relationships[newKey] = {
                data: {
                  type: 'ptz-preset',
                  id: item[key].id
                }
              };
              payload.included.push(this.normalizeItem('ptz-preset', item[key], payload));
            } else {
              newItem.relationships[newKey] = {
                data: {type: newKey, id: item[key]}
              };
            }
          }
        }
      } else if (key === 'data') {
        if (item[key]) {
          for (let dataKey in item[key]) {
            newItem.attributes['data_' + dataKey] = item[key][dataKey];
          }
        }
      } else if (key === 'centroid') {
        if (!item[key]) {
          newItem.attributes[key] = generateRandomPoint(
            {lat: 51.048615, lng: -114.07084},
            100000
          );
        } else {
          newItem.attributes[key] = item[key];
        }
      } else {
        if (key === 'type') {
          newItem.attributes[modelName + '_' + key] = item[key];
        } else {

          newItem.attributes[key] = item[key];
        }
      }
    }
    delete newItem.attributes['id'];
    delete newItem.attributes['data'];
    return newItem;
  },
  /**
   * this pulls out our pagination information
   **/
  normalizeQueryResponse(store, clazz, payload) {
    const result = this._super(...arguments);
    result.meta = result.meta || {};
    if (payload.links) {
      result.meta.pagination = this.createPageMeta(payload.links);
    }
    return result;
  },
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (!payload) {
      payload = {};
    }
    let newPayload = payload;
    if (!payload.data) {
      newPayload = {
        data: [],
        included: []
      };

      if (Array.isArray(payload)) {
        //legacy api doesn't use response object
        //this is from the /api/legacy/chanels/mute endpoint currently
        payload.forEach(item => {
          if (
            payload.length === 1 &&
            primaryModelClass.modelName !== 'subscription/subscribable'
          ) {
            newPayload.data = this.normalizeItem(
              primaryModelClass.modelName,
              item,
              newPayload
            );
          } else {
            newPayload.data.push(
              this.normalizeItem(primaryModelClass.modelName, item, newPayload)
            );
          }
        });
      } else if (Array.isArray(payload.results)) {
        payload.results.forEach(item => {
          newPayload.data.push(
            this.normalizeItem(primaryModelClass.modelName, item, newPayload)
          );
        });
      } else {
        newPayload.data = this.normalizeItem(
          primaryModelClass.modelName,
          payload,
          newPayload
        );
      }
    }
    newPayload.meta = {count: payload.count};
    newPayload.links = {
      prev: payload.previous,
      next: payload.next,
      first: 0,
      last: payload.count
    };
    return this._super(store, primaryModelClass, newPayload, id, requestType);
  },
  createPageMeta(data) {
    let meta = {};
    Object.keys(data)
      .forEach(type => {
        if (type == 'prev' || type == 'next') {
          const link = data[type];
          meta[type] = {};
          let a = document.createElement('a');
          a.href = link;

          a.search
            .slice(1)
            .split('&')
            .forEach(pairs => {
              const [param, value] = pairs.split('=');
              if (param == 'page') {
                meta[type].page = parseInt(value);
              }
              if (param == 'number') {
                meta[type].number = parseInt(value);
              }
              if (param == 'page_size') {
                meta[type].size = parseInt(value);
              }
            });
          a = null;
        } else {
          //number=1&page=2&page_size=5&size=5
          meta[type] = {};
          meta[type].number = parseInt(data[type]);
        }
      });
    meta.first.page = 1;

    if (meta.next.size) {
      meta.last.page = Math.ceil(meta.last.number / meta.next.size);
    } else if (meta.prev.size) {
      meta.last.page = Math.ceil(meta.last.number / meta.prev.size);
    } else {
      meta.last.page = 1;
    }

    meta.last.end = true;
    meta.first.start = true;
    if (!meta.next.size) {
      meta.next = meta.last;
      meta.self = meta.last;
    }
    if (!meta.prev.size) {
      meta.prev.page = 1;
      meta.prev.number = 1;
      meta.prev = meta.first;
      meta.self = meta.first;
    }
    if (!meta.self) {
      meta.self = {
        page: meta.next.page - 1,
        size: meta.next.size ? meta.next.size : meta.prev.size
      };
      if (!meta.prev.page) {
        meta.prev.page = meta.self.page - 1;
      }
    }
    if (!meta.prev.page) {
      meta.prev.page = 1;
    }
    meta.next.end = meta.last.page === meta.next.page;
    meta.prev.end = meta.last.page === meta.prev.page;
    meta.prev.start = meta.first.page === meta.prev.page;
    meta.next.start = meta.first.page === meta.next.page;
    return meta;
  },
  serialize(snapshot) {
    let json = {
      id: snapshot.id
    };
    snapshot.eachAttribute((key, attribute) => {
      this.serializeAttribute(snapshot, json, key, attribute);
    });
    snapshot.eachRelationship((key, relationship) => {
      if (relationship.kind === 'belongsTo') {
        let key = relationship.key;
        let belongsTo = snapshot.belongsTo(key);
        if (belongsTo) {
          json[underscore(key)] = belongsTo.id;
          if (key === 'channel') {
            json['channelSlug'] = belongsTo.record.slug;
          }
        }
      } else if (relationship.kind === 'hasMany') {
        this.serializeHasMany(snapshot, json, relationship);
      }
    });

    return json;
  },
  serializeAttribute(snapshot, json, key, attribute) {
    if (this._canSerialize(key)) {
      let type = attribute.type;
      let value = snapshot.attr(key);
      if (type) {
        let transform = this.transformFor(type);
        value = transform.serialize(value, attribute.options);
      }
      // if provided, use the mapping provided by `attrs` in
      // the serializer
      let payloadKey = this._getMappedKey(key, snapshot.type);

      if (payloadKey === key && this.keyForAttribute) {
        payloadKey = this.keyForAttribute(key, 'serialize');
      }

      json[underscore(payloadKey)] = value;
    }
  }
});
