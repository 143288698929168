import Route from '@ember/routing/route';
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';


export default Route.extend(UnauthenticatedRouteMixin, {
  beforeModel() {
    var url = window.location.href;
    const stateIndex = url.indexOf('state');
    let endIndex = url.indexOf('&', stateIndex);
    if (endIndex < 0) {
      endIndex = url.length;
    }
    let state = url.substring(stateIndex + 6, endIndex);
    let newLocation = url.replace('login', state);
    // the redirect url has to specify the tenant, in the provider/app config in the url
    // the replace below ensures users are sent to the correct page
    // this is because we believe cognito doesn't support redirect to *.ospreyreach.com/stuff
    newLocation = newLocation.replace('cognito-redirect', 'torii/redirect.html');
    window.location.replace(newLocation);
  }
});
